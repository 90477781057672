import { MEMBERS_AREA } from '@wix/app-definition-ids';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

type ReferralInfo =
  | 'editor_profile_settings'
  | 'editor_profile_manage_member_pages';

interface MembersAreaPublicApi {
  openGeneralSettingsPanel: (referralInfo: ReferralInfo) => Promise<void>;
  getIsMembersAreaV2Context: () => Promise<boolean>;
  getIsMembersAreaSeoEnabled: () => Promise<boolean>;
}

const getMembersAreaApi = (editorSDK: FlowEditorSDK) => {
  return editorSDK.document.application.getPublicAPI('', {
    appDefinitionId: MEMBERS_AREA,
  }) as Promise<MembersAreaPublicApi | undefined>;
};

export const openGeneralSettingsPanel = async (
  editorSDK: FlowEditorSDK,
  referralInfo: ReferralInfo,
) => {
  const membersAreaApi = await getMembersAreaApi(editorSDK);
  return membersAreaApi?.openGeneralSettingsPanel(referralInfo);
};

export const getIsMembersAreaV2Context = async (editorSDK: FlowEditorSDK) => {
  try {
    const membersAreaApi = await getMembersAreaApi(editorSDK);
    const isMembersAreaV2 = await membersAreaApi?.getIsMembersAreaV2Context();

    return !!isMembersAreaV2;
  } catch (e) {
    return false;
  }
};

export const getIsMembersAreaSeoEnabled = async (editorSDK: FlowEditorSDK) => {
  try {
    const membersAreaApi = await getMembersAreaApi(editorSDK);
    const isSeoChangesEnabled =
      await membersAreaApi?.getIsMembersAreaSeoEnabled();
    return !!isSeoChangesEnabled;
  } catch (e) {
    return false;
  }
};
